/* Change this file to get your personal Porfolio */

// Website related settings
const settings = {
  isSplash: false, // Change this to false if you don't want Splash screen.
};

//SEO Related settings
const seo = {
  title: "Soheil Jeibooie",
  description:
  "I'm passionate Full Stack web developer having an experience of web applications with Python, React.js &  Next.js, Experienced CyberSecurity Analyst and Ethical Hacker, Trying to Learn Blockchain development on Ethereum, Solidity & Web3.js.",
  og: {
    title: "Soheil Jeibooie",
    type: "website",
    url: "http://soheil-jei.ir",
  },
};

//Home Page
const greeting = {
  title: "Soheil Jeibooie",
  logo_name: "Soheil Jeibooie",
  nickname: "J3n3SiS",
  subTitle:
  "I'm passionate Full Stack web developer having an experience of web applications with Python, React.js &  Next.js, Experienced CyberSecurity Analyst and Ethical Hacker, Trying to Learn Blockchain development on Ethereum, Solidity & Web3.js.",
  resumeLink:
    "http://soheil-jei.ir",
  portfolio_repository: "https://github.com/sohiw/devportfolio",
  githubProfile: "https://github.com/sohiw",
};

const socialMediaLinks = [
  /* Your Social Media Link */
  // github: "https://github.com/ashutosh1919",
  // linkedin: "https://www.linkedin.com/in/ashutosh-hathidara-88710b138/",
  // gmail: "ashutoshhathidara98@gmail.com",
  // gitlab: "https://gitlab.com/ashutoshhathidara98",
  // facebook: "https://www.facebook.com/laymanbrother.19/",
  // twitter: "https://twitter.com/ashutosh_1919",
  // instagram: "https://www.instagram.com/layman_brother/"

  {
    name: "Github",
    link: "https://github.com/sohiw",
    fontAwesomeIcon: "fa-github", // Reference https://fontawesome.com/icons/github?style=brands
    backgroundColor: "#181717", // Reference https://simpleicons.org/?q=github
  },
  {
    name: "LinkedIn",
    link: "https://ir.linkedin.com/in/soheil-jeibooie-68b320140",
    fontAwesomeIcon: "fa-linkedin-in", // Reference https://fontawesome.com/icons/linkedin-in?style=brands
    backgroundColor: "#0077B5", // Reference https://simpleicons.org/?q=linkedin
  },
  {
    name: "Gmail",
    link: "mailto:itzsjei@gmail.com",
    fontAwesomeIcon: "fa-google", // Reference https://fontawesome.com/icons/google?style=brands
    backgroundColor: "#D14836", // Reference https://simpleicons.org/?q=gmail
  },
  {
    name: "Telegram",
    link: "https://t.me/GottSchlaft",
    fontAwesomeIcon: "fa-telegram", // Reference https://fontawesome.com/icons/facebook-f?style=brands
    backgroundColor: "#26A5E4", // Reference https://simpleicons.org/?q=facebook
  },
  {
    name: "Instagram",
    link: "https://www.instagram.com/soheil.jei/",
    fontAwesomeIcon: "fa-instagram", // Reference https://fontawesome.com/icons/instagram?style=brands
    backgroundColor: "#E4405F", // Reference https://simpleicons.org/?q=instagram
  },
];

const skills = {
  data: [
    {
      title: "CyberSecurity",
      fileName: "DataScienceImg",
      skills: [
					"⚡ Experience in Working to test security for multplie organizations",
					"⚡ Writing Private Scripts for Automated Red team testing",
					"⚡ Educated in CEHV11 &	Security+ Sy0-601",
					"⚡ Expreience in Hacking since 2015",
      ],
      softwareSkills: [
        {
          skillName: "",
          fontAwesomeClassname: "",
          style: {
            backgroundColor: "",
          },
        },
        {
          skillName: "Python",
          fontAwesomeClassname: "ion-logo-python",
          style: {
            backgroundColor: "transparent",
            color: "#3776AB",
          },
        },
        {
          skillName: "",
          fontAwesomeClassname: "",
          style: {
            backgroundColor: "",
            color: "",
          },
        },
        {
          skillName: "",
          fontAwesomeClassname: "",
          style: {
            backgroundColor: "",
          },
        },
      ],
    },
    {
      title: "Full Stack Development",
      fileName: "FullStackImg",
      skills: [
        "⚡ Building responsive website front end using React-Redux",
        "⚡ Developing mobile applications using Flutter, React Native and solo android apps using Kotlin",
        "⚡ Creating application backend in Node, Express & Flask",
				"⚡ Building responsive Single-Page-Apps (SPA) & PWA in React.js",
				"⚡ Building responsive static websites using Next.js",
				"⚡ Building Telegram Bots Using API & Telegram.dll - Telebot Library",
      ],
      softwareSkills: [
        {
          skillName: "HTML5",
          fontAwesomeClassname: "simple-icons:html5",
          style: {
            color: "#E34F26",
          },
        },
        {
          skillName: "CSS3",
          fontAwesomeClassname: "fa-css3",
          style: {
            color: "#1572B6",
          },
        },
        {
          skillName: "Sass",
          fontAwesomeClassname: "simple-icons:sass",
          style: {
            color: "#CC6699",
          },
        },
        {
          skillName: "JavaScript",
          fontAwesomeClassname: "simple-icons:javascript",
          style: {
            backgroundColor: "#000000",
            color: "#F7DF1E",
          },
        },
        {
          skillName: "ReactJS",
          fontAwesomeClassname: "simple-icons:react",
          style: {
            color: "#61DAFB",
          },
        },
        {
          skillName: "NodeJS",
          fontAwesomeClassname: "simple-icons:node-dot-js",
          style: {
            color: "#339933",
          },
        },
        {
          skillName: "NPM",
          fontAwesomeClassname: "simple-icons:npm",
          style: {
            color: "#CB3837",
          },
        },
        {
          skillName: "Yarn",
          fontAwesomeClassname: "simple-icons:yarn",
          style: {
            color: "#2C8EBB",
          },
        },
        {
          skillName: "Gatsby",
          fontAwesomeClassname: "simple-icons:gatsby",
          style: {
            color: "#663399",
          },
        },
        {
          skillName: "Flutter",
          fontAwesomeClassname: "simple-icons:flutter",
          style: {
            color: "#02569B",
          },
        },
      ],
    },
    {
      title: "Cloud Infra-Architecture",
      fileName: "CloudInfraImg",
      skills: [
        "⚡ Experience working on multiple cloud platforms",
        "⚡ Hosting and maintaining websites on virtual machine instances along with integration of databases",
        "⚡ Deploying deep learning models on cloud to use on mobile devices",
        "⚡ Setting up streaming jobs from DB to Server or vice-versa on GCP and AWS",
      ],
      softwareSkills: [
        {
          skillName: "GCP",
          fontAwesomeClassname: "simple-icons:googlecloud",
          style: {
            color: "#4285F4",
          },
        },
        {
          skillName: "AWS",
          fontAwesomeClassname: "simple-icons:amazonaws",
          style: {
            color: "#FF9900",
          },
        },
        {
          skillName: "Azure",
          fontAwesomeClassname: "simple-icons:microsoftazure",
          style: {
            color: "#0089D6",
          },
        },
        {
          skillName: "Firebase",
          fontAwesomeClassname: "simple-icons:firebase",
          style: {
            color: "#FFCA28",
          },
        },
        {
          skillName: "PostgreSQL",
          fontAwesomeClassname: "simple-icons:postgresql",
          style: {
            color: "#336791",
          },
        },
        {
          skillName: "MongoDB",
          fontAwesomeClassname: "simple-icons:mongodb",
          style: {
            color: "#47A248",
          },
        },
        {
          skillName: "Docker",
          fontAwesomeClassname: "simple-icons:docker",
          style: {
            color: "#1488C6",
          },
        },
        {
          skillName: "Kubernetes",
          fontAwesomeClassname: "simple-icons:kubernetes",
          style: {
            color: "#326CE5",
          },
        },
      ],
    },
    {
      title: "UI/UX Design",
      fileName: "DesignImg",
      skills: [
        "⚡ Designing highly attractive user interface for mobile and web applications",
        "⚡ Customizing logo designs and building logos from scratch",
        "⚡ Creating the flow of application functionalities to optimize user experience",
      ],
      softwareSkills: [
        {
          skillName: "Adobe XD",
          fontAwesomeClassname: "simple-icons:adobexd",
          style: {
            color: "#FF2BC2",
          },
        },
        {
          skillName: "Figma",
          fontAwesomeClassname: "simple-icons:figma",
          style: {
            color: "#F24E1E",
          },
        },
        {
          skillName: "Adobe Illustrator",
          fontAwesomeClassname: "simple-icons:adobeillustrator",
          style: {
            color: "#FF7C00",
          },
        },
        {
          skillName: "Inkscape",
          fontAwesomeClassname: "simple-icons:inkscape",
          style: {
            color: "#000000",
          },
        },
      ],
    },
  ],
};

// Education Page
const competitiveSites = {
  competitiveSites: [
  ],
};

const degrees = {
  degrees: [
    {
      title: "Sharif University Online Courses",
      subtitle: "B.Tech. in Computer Engineering",
      logo_path: "sharif_logo.jpeg",
      alt_name: "sharif",
      duration: "2015 - 2018",
      descriptions: [
        "⚡ I have studied basic software engineering subjects like DS, Algorithms.",
        "⚡ Apart from this, I have done courses on CyberSecurity, Telegram Bots, Cloud Computing and Full Stack Development.",
      ],
      website_link: "https://sharif.edu",
    },
    {
      title: "Islamshar University",
      subtitle: "Second year in Computer Science",
      logo_path: "iiau_logo.png",
      alt_name: "IslamshahrUni",
      duration: "2021 - Present",
      descriptions: [
        "⚡ I have taken varity of courses related to OOP, Graph Machine Learning, Computer Vision etc.",
        "⚡ Still Learning . . . ",
      ],
      website_link: "https://iiau.ac.ir",
    },
    {
      title: "CEH v11",
      subtitle: "Certified Ethical Hacker v11",
      logo_path: "CEH_logo.png",
      alt_name: "ceh",
      duration: "2021 - 2022",
      description : [
        "⚡ Studied in both Security+ & CEH v11",
        "⚡ Still Learning . . . ;D ",
      ],
      website_link: "https://www.eccouncil.org/",
    },
  ],
};

const certifications = {
  certifications: [
    {
      title: "CEH v11",
      subtitle: "- RIC MESSIER",
      logo_path: "CEH_logo.png",
      certificate_link:
        "",
      alt_name: "CEH",
      color_code: "#8C151599",
    },
    {
      title: "Security+",
      subtitle: "- Darill Gibson",
      logo_path: "Securityplus.png",
      certificate_link:
        "",
      alt_name: "Security+",
      color_code: "#00000099",
    },
  ],
};

// Experience Page
const experience = {
  title: "Experience",
  subtitle: "Work, Internship and Volunteership",
  description:
    "I have worked with many evolving startups as ML and DL Developer, Designer and Software Architect. I have also worked with some well established companies mostly as Ethical hacker & bug bounty hunter.",
  header_image_path: "experience.svg",
  sections: [
    {
      title: "Work",
      experiences: [
        {
          title: "Ethical Hacker",
          company: "Hacker one & BugCrowd",
          company_url: "https://hackeone.com",
          logo_path: "h1_logo.png",
          duration: "June 2022 - Present",
          location: "FreeLance",
          description:
          "I am working on buy bounty programs on hackerone & bugcrowd to help make internet a safer place for everyone.",
          color: "#0879bf",
        },
        {
          title: "Android and ML Developer",
          company: "iTeach",
          company_url: "",
          logo_path: "and_logo.png",
          duration: "May 2016 - Oct 2018",
          location: "Tehran, Iran",
          description:
            "I was involved in a team for creating complete software architecure of mobile and web application as well as admin panel for company called iTeach.",
          color: "#9b1578",
        },
        {
          title: "Telegram.dll & Telebot",
          company: "Telebot",
          company_url: "https://telegram.org",
          logo_path: "freecopy_logo.png",
          duration: "Nov 2016 - Dec 2017",
          location: "Tehran, Iran",
          description:
          "Contributed to Telegram.dll & helped developing Telebot(C#) Library for Telegram Bots.",
          color: "#fc1f20",
        },
      ],
    },
    {
      title: "Internships",
      experiences: [
        {
          title: "Machine Learning Intern",
          company: "TikTok Inc.",
          company_url: "https://www.tiktok.com/en/",
          logo_path: "tiktok_logo.png",
          duration: "May 2022 - Aug 2022",
          location: "FreeLance",
          description:
            "Building new features on the backend recommendation system, specifically ranking algorithms for Ads that touch hundreds of millions of people around the world. Improving online and offline content ranking algorithms by performing hard sample data replays for training steps.",
          color: "#000000",
        },
        {
          title: "Certified Ethical Hacker",
          company: "CEH v11",
          company_url: "",
          logo_path: "CEH_logo.png",
          duration: "May 2017 - Sept 2019",
          location: "Remote",
          description:
          "Learning all sorts of red team testing and pentesting, working with exploits, Active Directory, etc.",
          color: "#ee3c26",
        },
      ],
    },
    {
      title: "Volunteerships",
      experiences: [
        {
          title: "Google Explore ML Facilitator",
          company: "Google",
          company_url: "https://about.google/",
          logo_path: "google_logo.png",
          duration: "June 2019 - April 2020",
          location: "Hyderabad, Telangana",
          description:
            "Explore Machine Learning (ML) is a Google-sponsored program for university students to get started with Machine Learning. The curriculum offers 3 tracks of ML Content (Beginner, Intermediate, Advanced) and relies on university student facilitators to train other students on campus and to build opensource projects under this program.",
          color: "#4285F4",
        },
        {
          title: "Microsoft Student Partner",
          company: "Microsoft",
          company_url: "https://www.microsoft.com/",
          logo_path: "microsoft_logo.png",
          duration: "Aug 2019 - May 2020",
          location: "Hyderabad, Telangana",
          description:
            "Microsoft Student Partner is a program for university students to lead the awareness and use of Cloud especially Azure tools in the development of their projects and startups. Under this program, I have organised hands on workshops and seminars to teach Cloud Computing concepts to students.",
          color: "#D83B01",
        },
        {
          title: "Developer Program Member",
          company: "Github",
          company_url: "https://github.com/",
          logo_path: "github_logo.png",
          duration: "July 2019 - PRESENT",
          location: "Work From Home",
          description:
            "I am actively contributing to many opensource projects. I have contributed to projects of organisations like Tensorflow, Uber, Facebook, Google, Scikit-learn, Kiwix, Sympy, Python, NVLabs, Fossasia, Netrack, Keras etc. These contributions include bug fixes, feature requests and formulating proper documentation for project.",
          color: "#181717",
        },
      ],
    },
  ],
};

// Projects Page
const projectsHeader = {
  title: "Projects",
  description:
    "My projects makes use of vast variety of latest technology tools. My best experience is to create WebApps projects and deploy them using cloud infrastructure.",
  avatar_image_path: "projects_image.svg",
};

const publicationsHeader = {
  title: "Publications",
  description:
    "I have worked on and published a few research papers and publications of my own.",
  avatar_image_path: "projects_image.svg",
};

const publications = {
  data: [
  ],
};

// Contact Page
const contactPageData = {
  contactSection: {
    title: "Contact Me",
    profile_image_path: "js.jpeg",
    description:
      "I am available on almost every social media. You can message me, I will reply within 24 hours. I can help you with ML, EH, React, Android, Cloud and Opensource Development.",
  },
  blogSection: {
    title: "Projects",
    subtitle:
      "All my public projects on github are opensource so feel free to clone, fork, change & use it however you like.",
    link: "https://github.com/sohiw",
    avatar_image_path: "blogs_image.svg",
  },
  addressSection: {
    title: "Address",
    subtitle:
      "Iran, Tehran, Sazman Ab St.",
    avatar_image_path: "address_image.svg",
    location_map_link: "https://goo.gl/maps/EZqnfM3stHArUpw78",
  },
  phoneSection: {
    title: "",
    subtitle: "",
  },
};

export {
  settings,
  seo,
  greeting,
  socialMediaLinks,
  skills,
  competitiveSites,
  degrees,
  certifications,
  experience,
  projectsHeader,
  publicationsHeader,
  publications,
  contactPageData,
};
